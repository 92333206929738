import 'proxy-polyfill/proxy.min.js';
import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import App from './App.svelte';
import { environment, release } from 'config';

Sentry.init({
  dsn: 'https://10d4dfca584a46c1a62016195de077f0@o491768.ingest.sentry.io/5557849',
  environment,
  release,

  integrations: [
    new Integrations.BrowserTracing()
  ],

  tracesSampleRate: 1.0,

  beforeSend(event, { originalException }) {
    try {
      if (originalException && originalException.message) {
        // These are errors related to paypal logging, which are blocked by
        // some adblockers.
        if (originalException.message.match(/\/xoplatform\//)) return null;
      }
    } catch (error) {
      // Noop
    }

    return event;
  }
});

const app = new App({
  target: document.body
});

export default app;
